import { IconProps } from "Icons/types";

export const RefreshCw02Icon = ({ width = 24, height = 24 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6569 6.34315C14.5327 3.21895 9.46734 3.21895 6.34315 6.34315C5.23515 7.45114 4.52139 8.80057 4.19904 10.2213C4.07684 10.7599 3.54115 11.0974 3.00256 10.9752C2.46396 10.853 2.12641 10.3173 2.24862 9.77873C2.652 8.00086 3.54638 6.31149 4.92893 4.92893C8.83418 1.02369 15.1658 1.02369 19.0711 4.92893C19.8691 5.72692 20.5003 6.3951 21 6.95359V4C21 3.44772 21.4477 3 22 3C22.5523 3 23 3.44772 23 4V10C23 10.5523 22.5523 11 22 11H16C15.4477 11 15 10.5523 15 10C15 9.44772 15.4477 9 16 9H20.1257C19.6137 8.38306 18.8352 7.52152 17.6569 6.34315ZM20.9974 13.0248C21.536 13.147 21.8736 13.6827 21.7514 14.2213C21.348 15.9991 20.4536 17.6885 19.0711 19.0711C15.1658 22.9763 8.83418 22.9763 4.92893 19.0711C4.13094 18.2731 3.49975 17.6049 3 17.0464V20C3 20.5523 2.55228 21 2 21C1.44772 21 1 20.5523 1 20V14C1 13.4477 1.44772 13 2 13H8C8.55228 13 9 13.4477 9 14C9 14.5523 8.55228 15 8 15H3.87429C4.38627 15.6169 5.16477 16.4785 6.34315 17.6569C9.46734 20.781 14.5327 20.781 17.6569 17.6569C18.7648 16.5489 19.4786 15.1994 19.801 13.7787C19.9232 13.2401 20.4588 12.9026 20.9974 13.0248Z"
      fill="#3E3E3E"
    />
  </svg>
);
