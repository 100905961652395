import {
  addDays,
  addHours,
  addWeeks,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  format,
  intervalToDuration,
  formatDistance,
  isBefore,
  nextDay,
  startOfToday,
} from "date-fns";

type TimeUnitHelpersAndStr = [
  (dateLeft: number | Date, dateRight: number | Date) => number,
  string,
  string
];

/**
 * This util finds out how much time elapsed between 2 dates in the most relevant unit
 * @param date
 * @returns a string
 */
export const getApproxDurationBetweenDates = (
  date: Date,
  comparedToDate: Date,
  isShortFormat = false
) => {
  const helpersAndStrs: TimeUnitHelpersAndStr[] = [
    [differenceInYears, "y", "year"],
    [differenceInMonths, "m", "month"],
    [differenceInWeeks, "w", "week"],
    [differenceInDays, "d", "day"],
    [differenceInHours, "h", "hour"],
    [differenceInMinutes, "m", "minute"],
    [differenceInSeconds, "s", "second"],
  ];

  for (const [apiHelper, shortTimeUnitStr, fullTimeUnitStr] of helpersAndStrs) {
    const timeElapsedInUnit = apiHelper(comparedToDate, date);
    if (timeElapsedInUnit <= 0) continue;
    if (isShortFormat) {
      return `${timeElapsedInUnit}${shortTimeUnitStr}`;
    } else if (timeElapsedInUnit > 1) {
      return `${timeElapsedInUnit} ${fullTimeUnitStr}s`;
    } else {
      return `${timeElapsedInUnit} ${fullTimeUnitStr}`;
    }
  }

  return isShortFormat ? "0s" : "0 seconds";
};

/**
 * This util finds out how much time elapsed since a date in the most relevant unit
 * @param date
 * @returns a string
 */
export const getApproxDurationSinceDate = (date: Date) => {
  return getApproxDurationBetweenDates(date, new Date(), true);
};

/**
 * This util finds out how much time elapsed between 2 dates in years and months
 * @param date
 * @returns a string
 */
export const getYearsAndMonthsBetweenDates = (
  date: Date,
  comparedToDate: Date,
  isShortFormat = false
) => {
  const diffInYears = differenceInYears(comparedToDate, date);
  const diffInMonths = differenceInMonths(comparedToDate, date);
  const remainderMonths = diffInMonths - diffInYears * 12;

  // special case for less than one month
  if (diffInYears === 0 && remainderMonths === 0 && !isShortFormat) {
    return "<1 Month";
  } else if (diffInYears === 0 && remainderMonths === 0 && isShortFormat) {
    return "<1m";
  }

  if (isShortFormat) {
    const durationStrings = [];
    if (diffInYears > 0) durationStrings.push(`${diffInYears}y`);
    if (remainderMonths > 0 || diffInYears === 0)
      durationStrings.push(`${remainderMonths}m`);
    return durationStrings.join(" ");
  }

  const durationStrings = [];
  if (diffInYears > 0)
    durationStrings.push(`${diffInYears} Year${diffInYears > 1 ? "s" : ""}`);
  if (remainderMonths > 0 || diffInYears === 0)
    durationStrings.push(
      `${remainderMonths} Month${remainderMonths > 1 ? "s" : ""}`
    );
  return durationStrings.join(" ");
};

export const getFormattedDateAtTime = (date: Date) => {
  // if older than 1 year, show year in format: "September 12, 2020 at 3:00 PM"
  if (differenceInYears(new Date(), date) > 0) {
    return `${getFormattedDateAndYear(new Date(date))} at ${format(
      new Date(date),
      "h:mm a"
    )}`;
  } else {
    return `${getFormattedDate(new Date(date))} at ${format(
      new Date(date),
      "h:mm a"
    )}`;
  }
};

export const getFormatDistanceDate = (date: Date) => {
  return formatDistance(date, new Date(), { addSuffix: true });
};

export const getAbbreviatedFormatDistanceDate = (date: Date) => {
  return getFormatDistanceDate(date)
    .replace("minute", "min")
    .replace("minutes", "mins")
    .replace("second", "sec")
    .replace("seconds", "secs");
};

export const getFormattedDateAndYear = (date: Date) => {
  return format(new Date(date), "MMMM d, yyyy");
};

export const getFormattedMonthAndYear = (date: Date) => {
  return format(new Date(date), "MMMM yyyy");
};

export const getFormattedDate = (date: Date) => {
  return format(new Date(date), "MMMM d");
};

// if this function is updated, please make sure nextDayOccurrence is still working
export const getLocalWeekDay = (dateString: string) =>
  new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(new Date(dateString));

export const getLocalHour = (dateString: string) =>
  new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
  }).format(new Date(dateString));

// this will get the next date of the weekday of the input relative to the current date.
export const nextWeekDayFromTomorrow = (weekDayDateString: string) => {
  const weekDay = getLocalWeekDay(weekDayDateString);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const now = new Date();
  const today = new Date().setHours(0, 0, 0, 0);
  const nextDate = addHours(
    nextDay(today, weekdays.indexOf(weekDay) as Day),
    new Date(weekDayDateString).getHours()
  ); // this is the next Date that should be the payment

  if (!isBefore(addDays(new Date(), 1), nextDate)) {
    // If less than 24 hours, add another week to the result
    return format(addWeeks(nextDate, 1), "EEEE, MMM d");
  }
  return format(nextDay(now, weekdays.indexOf(weekDay) as Day), "EEEE, MMM d");
};

export const getDayPeriod = (): "morning" | "dayTime" | "evening" => {
  const hoursSinceStartOfDay = differenceInHours(new Date(), startOfToday(), {
    roundingMethod: "floor",
  });

  if (3 <= hoursSinceStartOfDay && hoursSinceStartOfDay < 12) return "morning";
  if (12 <= hoursSinceStartOfDay && hoursSinceStartOfDay < 18) return "dayTime";
  return "evening";
};

// returns a string hh:mm:ss from a number of seconds
export const formatCounter = (seconds: number): string => {
  const callDuration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const pad = (number = 0) => String(number).padStart(2, "0");
  const mins = pad(
    (callDuration.minutes || 0) +
      (callDuration.hours || 0) * 60 +
      (callDuration.days || 0) * 60 * 24
  );
  const secs = pad(callDuration.seconds);

  return `${mins}:${secs}`;
};

export const formatSecondsToTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};
