import { useSelector } from "react-redux";

import { logger } from "interfaces/logger";
import { addPlan, selectPlanLimits } from "store/planSlice";
import store from "store/store";

import { UserPlan } from "./accountProfile";
import { fetchAuthWrapper } from "./backend";
import { supabase } from "./supabaseClient";

type RecurringInterval = "month" | "year";

export type PlanLimits = {
  id: UserPlan;
  maxSavedJobs: number;
  maxAppliedJobs: number;
  maxResumeAnalysis: number;
  maxPracticeRounds: number;
  maxChatAIAssistantResponse: number;
};

export type UserPlanDetails = {
  cancelAt?: number;
  currentPeriodEnd: number;
  unitAmountDecimal: number;
  recurringInterval: RecurringInterval;
  couponDurationInMonths?: number;
  couponAmountOff?: number;
  couponPercentOff?: number;
};

export type CatalogPrice = {
  stripePriceId: string;
  productName: string;
  recurringInterval: RecurringInterval;
  unitAmountDecimal: number;
};

type BeCatalogPrice = {
  stripe_price_id: string;
  product_name: string;
  recurring_interval: string;
  unit_amount_decimal: number;
};

export const getCatalogPricing = async (): Promise<{
  error?: string;
  catalogPrices?: CatalogPrice[];
}> => {
  const res = await fetchAuthWrapper.get("/be-api/get-catalog-pricing");

  if (res.status === 504) {
    logger("Error (timeout) calling get-catalog-pricing", "error");
    return { error: "Request timed out" };
  }

  if (res.status !== 200) {
    logger("Error calling get-catalog-pricing", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return {
    catalogPrices: body.catalog_prices.map((catalog_price: BeCatalogPrice) => ({
      stripePriceId: catalog_price.stripe_price_id,
      productName: catalog_price.product_name,
      recurringInterval: catalog_price.recurring_interval,
      unitAmountDecimal: catalog_price.unit_amount_decimal,
    })),
  };
};

export const getUserPlanDetails = async (): Promise<{
  error?: string;
  userPlanDetails?: UserPlanDetails;
}> => {
  const res = await fetchAuthWrapper.get("/be-api/get-user-plan-details");

  if (res.status !== 200) {
    logger("Error calling get-user-plan-details", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();
  // the endpoint returns the plan details only if found
  // it is possible that a user has no plan so it can return an empty body
  if (!body) return { userPlanDetails: undefined };

  return {
    userPlanDetails: {
      cancelAt: body.cancel_at,
      currentPeriodEnd: body.current_period_end,
      unitAmountDecimal: body.unit_amount_decimal,
      recurringInterval: body.recurring_interval,
      couponDurationInMonths: body.coupon_duration_in_months,
      couponAmountOff: body.coupon_amount_off,
      couponPercentOff: body.coupon_percent_off,
    },
  };
};

export const setupStripeCheckoutSession = async ({
  planId,
  sourceUrl,
  priceId,
}: {
  planId: string;
  sourceUrl: string;
  priceId: string;
}): Promise<{
  error?: string;
  checkoutUrl?: string;
}> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/setup-stripe-checkout-session",
    { planId, sourceUrl, priceId }
  );

  if (res.status !== 200) {
    logger("Error calling setup-stripe-checkout-session", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { checkoutUrl: body.checkout_url };
};

export const getStripeSubscriptionPortal = async ({
  sourceUrl,
}: {
  sourceUrl: string;
}): Promise<{
  error?: string;
  portalUrl?: string;
}> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/get-stripe-subscription-portal",
    { sourceUrl }
  );

  if (res.status !== 200) {
    logger("Error calling get-stripe-subscription-portal", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { portalUrl: body.stripe_subscription_portal_url };
};

const getPlanLimits = async (plan: UserPlan): Promise<PlanLimits> => {
  const { data, error } = await supabase
    .from("plan")
    .select("*")
    .eq("id", plan)
    .single();

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  const planLimits = {
    id: data.id,
    maxAppliedJobs: data.max_applied_jobs || Infinity, //when null on db means infinity in the FE
    maxSavedJobs: data.max_saved_jobs || Infinity, //when null on db means infinity in the FE
    maxResumeAnalysis: data.max_resume_analysis || Infinity, //when null on db means infinity in the FE
    maxPracticeRounds: data.max_practice_rounds || Infinity, //when null on db means infinity in the FE
    maxChatAIAssistantResponse: data.max_chat_ai_assistant_response || Infinity, //when null on db means infinity in the FE
  };
  store.dispatch(addPlan(planLimits));
  return planLimits;
};

export const usePlanLimits = (
  plan: UserPlan
):
  | { planLimits: PlanLimits; isLoading: false }
  | { planLimits: undefined; isLoading: true } => {
  const planLimits = useSelector(selectPlanLimits(plan));
  if (!planLimits) {
    getPlanLimits(plan);
    return { planLimits, isLoading: true };
  } else {
    return { planLimits, isLoading: false };
  }
};
