import { SerializedStyles, css } from "@emotion/react";

import { uiGray } from "constants/colors";

const style = {
  divider: css({
    margin: "24px 0",
    border: "none",
    borderBottom: `1px solid ${uiGray[10]}`,
  }),
  isVertical: css({
    margin: "0 24px",
    border: "none",
    borderLeft: `1px solid ${uiGray[10]}`,
  }),
};

export const Divider = ({
  customCss,
  isVertical,
}: {
  customCss?: SerializedStyles;
  isVertical?: boolean;
}) => {
  return (
    <hr
      data-testid="divider"
      css={[isVertical ? style.isVertical : style.divider, customCss]}
    />
  );
};
