import { useEffect, useState } from "react";

import { SerializedStyles, css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { purple, uiGray, white } from "constants/colors";

import { ButtonWrapper } from "./ButtonWrapper";
import { Typography } from "./Typography";

const style = {
  li: css({
    listStyle: "none",
  }),
  option: css({
    padding: "8px 12px",
    borderRadius: 8,
    width: "100%",
    gap: 8,
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    "&:focus": {
      outline: "none",
      boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
      border: `1px solid ${purple[600]}`,
      background: purple[50],
    },
    "&:hover": { background: purple[50] },
  }),
  empty: css({
    padding: "8px 12px",
    borderRadius: 8,
  }),
  wrapper: css({
    width: "100%",
    background: white,
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 0px 0px 1px #F5F6FA, 0px 0px 8px rgba(72, 40, 228, 0.15)",
    zIndex: 2,
  }),
  isAbsolute: css({
    position: "absolute",
    bottom: 0,
    right: 0,
    transform: "translateY(calc(100% + 11px))",
  }),
  ul: css({
    listStyleType: "none",
    margin: 0,
    padding: 4,
  }),
};

export type OptionType = {
  name: string;
  label: string;
  type?: string;
};

type Props<T> = {
  options: T[];
  selectedValue?: string;
  customEmptyStateLabel?: string;
  OptionComponent?: (props: T) => EmotionJSX.Element;
  FooterComponent?: EmotionJSX.Element;
  HeaderComponent?: EmotionJSX.Element;
  onChange: (value: T, index?: number) => void;
  isVisible: boolean;
  optionsListId?: string;
  customCss?: {
    wrapper?: SerializedStyles;
    option?: SerializedStyles;
  };
  stopPropagation?: boolean;
  isAbsolute?: boolean;
};

export const OptionsMenu = <T extends OptionType>({
  options,
  selectedValue,
  OptionComponent,
  FooterComponent,
  HeaderComponent,
  onChange,
  isVisible,
  optionsListId,
  customCss,
  customEmptyStateLabel = "There are no options matching this value",
  stopPropagation,
  isAbsolute = true,
}: Props<T>) => {
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        // Handle up arrow key
        setFocusedIndex((prevIndex) => {
          if (prevIndex === null) return options.length - 1;
          return prevIndex === 0 ? options.length - 1 : prevIndex - 1;
        });
      } else if (event.key === "ArrowDown") {
        // Handle down arrow key
        setFocusedIndex((prevIndex) => {
          if (prevIndex === null) return 0;
          return (prevIndex + 1) % options.length;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [options]);

  if (!isVisible) return null;

  return (
    <div
      css={[
        style.wrapper,
        isAbsolute ? style.isAbsolute : undefined,
        customCss?.wrapper,
      ]}
    >
      {HeaderComponent && HeaderComponent}
      <ul css={style.ul} id={optionsListId}>
        {options.length ? (
          options.map((option, index) => (
            <li key={option.name} css={style.li}>
              <ButtonWrapper
                role={"option"}
                customCss={[style.option, customCss?.option]}
                autoFocus={
                  selectedValue === option.name || focusedIndex === index
                }
                ariaLabel={option.label}
                onClick={(e) => {
                  if (stopPropagation) e.stopPropagation();
                  onChange(option, index);
                }}
                disabled={option.type == "divider"}
              >
                {OptionComponent ? (
                  <OptionComponent {...option} />
                ) : (
                  <Typography color={uiGray[80]} weight="medium" size="XS">
                    {option.label}
                  </Typography>
                )}
              </ButtonWrapper>
            </li>
          ))
        ) : (
          <Typography
            tag="p"
            customCss={style.empty}
            color={uiGray[60]}
            size="XS"
          >
            {customEmptyStateLabel}
          </Typography>
        )}
      </ul>
      {FooterComponent && FooterComponent}
    </div>
  );
};
