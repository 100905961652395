import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const CheckCircleIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[90],
  strokeGradient,
  strokeGradientId,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-testid="check-circle-icon"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    {strokeGradient && strokeGradientId && strokeGradient()}
    <path
      d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke={
        strokeGradientId && strokeGradient
          ? `url(#${strokeGradientId})`
          : stroke
      }
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
