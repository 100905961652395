import { ChevronDownIcon } from "Icons/ChevronDown";
import { ChevronUpIcon } from "Icons/ChevronUp";
import { IconType } from "Icons/types";

import { useState } from "react";

import { SerializedStyles, css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { useCloseOnClickOutside } from "utils/libraryHooks";

import { purple, uiGray, white } from "constants/colors";

import { FieldLabel } from "./FieldLabel";
import { OptionType, OptionsMenu } from "./OptionsMenu";
import { Typography } from "./Typography";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  }),

  label: css({ marginBottom: 8 }),
  input: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 14px",
    background: white,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: uiGray[10],
    borderRadius: 12,
    gap: 8,
    "&:focus": {
      outline: `1px solid ${purple[600]}`,
      boxShadow: `0px 0px 0px 4px ${purple.focusShadow}`,
    },
    "&::placeholder": {
      color: uiGray[60],
    },
    "&:disabled": {
      color: uiGray[50],
      background: "rgba(141, 141, 141, 0.05)",
      borderColor: "#ECECEC",
    },
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    color: uiGray[100],
    flex: 1,
  }),
};

type Props<T> = {
  onChange: (value?: string) => void;
  options: T[];
  label: string;
  defaultValue?: string;
  displayLabel?: boolean;
  isOptional?: boolean;
  IconLeft?: IconType;
  OptionComponent?: (props: T) => EmotionJSX.Element;
  placeholder?: string;
  customCss?: {
    wrapper?: SerializedStyles;
    button?: SerializedStyles;
    text?: SerializedStyles;
  };
};

export const DropdownDeprecated = <T extends OptionType>({
  onChange,
  options,
  label,
  displayLabel = true,
  isOptional,
  defaultValue,
  OptionComponent,
  placeholder,
  IconLeft,
  customCss,
}: Props<T>) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    defaultValue
  );
  const listId = `dropdown-${label.replaceAll(" ", "-")}`; // this is used to differentiate when multiple dropdown on same page
  useCloseOnClickOutside(showOptions, setShowOptions, listId);

  return (
    <div css={customCss?.wrapper}>
      {displayLabel && <FieldLabel label={label} isOptional={isOptional} />}
      <div css={style.wrapper} id={listId}>
        <button
          role="combobox"
          aria-label={label}
          css={[style.input, customCss?.button]}
          onClick={() => {
            setShowOptions((state) => !state);
          }}
        >
          {IconLeft && <IconLeft stroke={purple[600]} width={16} height={16} />}
          {placeholder && !selectedValue && (
            <Typography color={uiGray[60]} customCss={customCss?.text}>
              {placeholder}
            </Typography>
          )}
          <Typography customCss={customCss?.text}>
            {options.find((option) => option.name === selectedValue)?.label}
          </Typography>
          {showOptions ? (
            <ChevronUpIcon width={16} height={16} stroke={uiGray[90]} />
          ) : (
            <ChevronDownIcon width={16} height={16} stroke={uiGray[90]} />
          )}
        </button>
        <OptionsMenu
          options={options}
          onChange={(option) => {
            onChange(option.name);
            setSelectedValue(option.name);
            setShowOptions(false);
          }}
          isVisible={showOptions}
          OptionComponent={OptionComponent}
          selectedValue={selectedValue}
        />
      </div>
    </div>
  );
};
