import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const CheckIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[80],
}: IconProps) => (
  <svg
    data-testid="check-icon"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20 6L9 17L4 12"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
